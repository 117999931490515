import React, { useEffect, useState } from 'react';
import useSwr from 'swr';
import fetcher from 'utils/fetcher';

function RepData(){
  const [distData, setDistdata] = useState();
  var { data: distributorsData } = useSwr(`/api/distributor/all/approved`, fetcher);

  useEffect(() => {
    if(distributorsData){
      setDistdata(distributorsData)
    }
  }, [distributorsData]);

  console.log('Show me dist data 1.0 =>', distData);

  const alldata =  [
    {
      type: 'NA',
      companyname: 'George Grant Company',
      region: 'Alabama',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '4513 Hixson Pike, Suite 106',
      city: 'Hixson',
      state: 'Tennessee',
      zip: '37343',
      phone: '1-423-875-3241',
      mobile: '',
      tollfree: '1-800-445-2185',
      fax: '1-423-875-3260',
      email: 'sales@georgegrantco.com',
      website: 'https://georgegrantco.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'George Grant Company',
      region: 'Arkansas',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '4513 Hixson Pike, Suite 106',
      city: 'Hixson',
      state: 'Tennessee',
      zip: '37343',
      phone: '1-423-875-3241',
      mobile: '',
      tollfree: '1-800-445-2185',
      fax: '1-423-875-3260',
      email: 'sales@georgegrantco.com',
      website: 'https://georgegrantco.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'George Grant Company',
      region: 'Georgia',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '4513 Hixson Pike, Suite 106',
      city: 'Hixson',
      state: 'Tennessee',
      zip: '37343',
      phone: '1-423-875-3241',
      mobile: '',
      tollfree: '1-800-445-2185',
      fax: '1-423-875-3260',
      email: 'sales@georgegrantco.com',
      website: 'https://georgegrantco.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'George Grant Company',
      region: 'Tennessee',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '4513 Hixson Pike, Suite 106',
      city: 'Hixson',
      state: 'Tennessee',
      zip: '37343',
      phone: '1-423-875-3241',
      mobile: '',
      tollfree: '1-800-445-2185',
      fax: '1-423-875-3260',
      email: 'sales@georgegrantco.com',
      website: 'https://georgegrantco.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'George Grant Company - Thom Whiten',
      region: 'Florida',
      firstname: 'Thom',
      lastname: 'Whiten',
      country: 'USA',
      address: '840 Spaulding Drive',
      city: 'Atlanta',
      state: 'Georgia',
      zip: '30328',
      phone: '1-770-394-2735',
      mobile: '1-423-645-6668',
      tollfree: '',
      fax: '',
      email: 'thom@georgegrantco.com',
      website: 'https://georgegrantco.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'George Grant Company - Randy Shanks',
      region: 'Mississippi',
      firstname: 'Randy',
      lastname: 'Shanks',
      country: 'USA',
      address: '1110 Oliver Street',
      city: 'Pascagoula',
      state: 'Mississippi',
      zip: '39567',
      phone: '',
      mobile: '1-251-454-8859',
      tollfree: '',
      fax: '',
      email: 'randy@georgegrantco.com',
      website: 'https://georgegrantco.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'MCR Technologies',
      region: 'Southern California',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '15615 Alton Parkway, Suite 245',
      city: 'Irvine',
      state: 'California',
      zip: '92618',
      phone: '1-949-783-3100',
      mobile: '',
      tollfree: '',
      fax: '1-949-783-3101',
      email: 'sales@mcrt.com',
      website: 'https://mcrt.com',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Columbine Control Company',
      region: 'Colorado',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '7390 Fraser Street, Unit D',
      city: 'Centennial',
      state: 'Colorado',
      zip: '80112',
      phone: '1-303-680-0775',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'info@columbinecontrol.com',
      website: 'https://columbinecontrol.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Comtel Corporation',
      region: 'Indiana',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '37000 Plymouth Road',
      city: 'Livonia',
      state: 'Michigan',
      zip: '48150',
      phone: '1-800-335-2505',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'ehubbard@comtel.com',
      website: '',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Chaltron Systems, Inc.',
      region: 'Kentucky',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '7757 Auburn Rd., #14',
      city: 'Concord',
      state: 'Ohio',
      zip: '44077',
      phone: '1-440-639-6400',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'sales@chaltron.com',
      website: 'https://chaltron.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Chaltron Systems, Inc.',
      region: 'Ohio',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '7757 Auburn Rd., #14',
      city: 'Concord',
      state: 'Ohio',
      zip: '44077',
      phone: '1-440-639-6400',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'sales@chaltron.com',
      website: 'https://chaltron.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Microwave Precision Instruments',
      region: 'Louisiana',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '200 Cummings Rd.',
      city: 'Broussard',
      state: 'Louisiana',
      zip: '705018',
      phone: '1-844-674-7466',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'info@microwave-precision.com',
      website: 'https://microwave-precision.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Microwave Precision Instruments',
      region: 'Texas',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '17461 Village Green Dr.',
      city: 'Houston',
      state: 'Texas',
      zip: '77040',
      phone: '1-844-674-7466',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'info@microwave-precision.com',
      website: 'https://microwave-precision.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Arjay Automation, Inc.',
      region: 'Michigan (Upper Peninsula)',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '1178 East Cliff Road',
      city: 'Burnsville',
      state: 'Minnesota',
      zip: '55337',
      phone: '1-800-761-1749',
      mobile: '',
      tollfree: '',
      fax: '1-612-861-4292',
      email: 'salesleads@arjaynet.com',
      website: '',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Arjay Automation, Inc.',
      region: 'Minnesota',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '1178 East Cliff Road',
      city: 'Burnsville',
      state: 'Minnesota',
      zip: '55337',
      phone: '1-800-761-1749',
      mobile: '',
      tollfree: '',
      fax: '1-612-861-4292',
      email: 'salesleads@arjaynet.com',
      website: '',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Arjay Automation, Inc.',
      region: 'North Dakota',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '1178 East Cliff Road',
      city: 'Burnsville',
      state: 'Minnesota',
      zip: '55337',
      phone: '1-800-761-1749',
      mobile: '',
      tollfree: '',
      fax: '1-612-861-4292',
      email: 'salesleads@arjaynet.com',
      website: '',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Arjay Automation, Inc.',
      region: 'South Dakota',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '1178 East Cliff Road',
      city: 'Burnsville',
      state: 'Minnesota',
      zip: '55337',
      phone: '1-800-761-1749',
      mobile: '',
      tollfree: '',
      fax: '1-612-861-4292',
      email: 'salesleads@arjaynet.com',
      website: '',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Arjay Automation, Inc.',
      region: 'Wisconsin',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '1178 East Cliff Road',
      city: 'Burnsville',
      state: 'Minnesota',
      zip: '55337',
      phone: '1-800-761-1749',
      mobile: '',
      tollfree: '',
      fax: '1-612-861-4292',
      email: 'salesleads@arjaynet.com',
      website: '',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Total Flow Control',
      region: 'New Mexico',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '8400 South Kyrene Rd., Suite #113',
      city: 'Tempe',
      state: 'Arizona',
      zip: '85284',
      phone: '1-480-222-5757',
      mobile: '',
      tollfree: '',
      fax: '',
      email: '',
      website: '',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'DynaTech Control Solutions, Inc.',
      region: 'Upper New York State',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '15 Hazelwood Drive, Suite 100',
      city: 'Amherst',
      state: 'New York',
      zip: '14228',
      phone: '1-716-877-7180',
      mobile: '',
      tollfree: '',
      fax: '1-716-877-7181',
      email: 'carl.mende@dynatechcs.com',
      website: 'https://dynatechcs.com',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Robert S. Hudgins Co.',
      region: 'North Carolina',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '1200 4 Lakes Drive',
      city: 'Matthews',
      state: 'North Carolina',
      zip: '28106',
      phone: '1-877-841-7744',
      mobile: '',
      tollfree: '',
      fax: '1-704-841-7750',
      email: 'sales@rshudgins.com',
      website: 'https://rshudgins.com',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Robert S. Hudgins Co.',
      region: 'South Carolina',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '1200 4 Lakes Drive',
      city: 'Matthews',
      state: 'North Carolina',
      zip: '28106',
      phone: '1-877-841-7744',
      mobile: '',
      tollfree: '',
      fax: '1-704-841-7750',
      email: 'sales@rshudgins.com',
      website: 'https://rshudgins.com',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Branom Instrument Co.',
      region: 'Oregon',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '5500 4th Avenue South',
      city: 'Seattle',
      state: 'Washington',
      zip: '98108',
      phone: '1-206-762-6050',
      mobile: '1-206-767-5669',
      tollfree: '',
      fax: '',
      email: 'sales@branom.com',
      website: '',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Sterling Engineering Solutions, Inc.',
      region: 'Virginia',
      firstname: '',
      lastname: '',
      country: 'USA',
      address: '111 Goodwin Avenue',
      city: 'Salem',
      state: 'Virginia',
      zip: '24153',
      phone: '1-540-375-0923',
      mobile: '',
      tollfree: '',
      fax: '1-540-375-0924',
      email: 'sales@sterlinges.com',
      website: 'https://sterlinges.com',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Davis Controls, Ltd.',
      region: 'Alberta',
      firstname: '',
      lastname: '',
      country: 'Canada',
      address: '16-4620 Manilla Road, S.E.',
      city: 'Calgary',
      state: 'AB',
      zip: 'T2G 4B7',
      phone: '403-255-5035',
      mobile: '',
      tollfree: '',
      fax: '403-255-5077',
      email: 'info@daviscontrols.com',
      website: 'https://www.daviscontrols.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Strata Controls',
      region: 'Alberta',
      firstname: '',
      lastname: '',
      country: 'Canada',
      address: '612-500 Country Hills Blvd. NE, Suite #348',
      city: 'Calgary',
      state: 'AB',
      zip: 'T3K 5K3',
      phone: '403-874-0571',
      mobile: '',
      tollfree: '',
      fax: '403-241-8023',
      email: 'vince.madsen@stratacontrols.com',
      website: 'https://www.stratacontrols.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Cascadia Instrumentation, Inc.',
      region: 'British Columbia',
      firstname: '',
      lastname: '',
      country: 'Canada',
      address: '7360 – 137 Street, Suite 383',
      city: 'Surrey',
      state: 'BC',
      zip: 'V3W 6M2',
      phone: '778-578-7956',
      mobile: '',
      tollfree: '',
      fax: '778-578-7986',
      email: 'brian.higgs@cascadia-instrumentation.com',
      website: 'https://www.cascadia-instrumentation.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Trillium Measurement and Controls',
      region: 'Ontario',
      firstname: '',
      lastname: '',
      country: 'Canada',
      address: '2800 John Street, Unit 12',
      city: 'Markham',
      state: 'ON',
      zip: 'L3R 0E2',
      phone: '905-305-7790',
      mobile: '',
      tollfree: '',
      fax: '905-305-7791',
      email: '',
      website: 'https://www.calflow.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Davis Controls, Ltd.',
      region: 'Ontario',
      firstname: '',
      lastname: '',
      country: 'Canada',
      address: '2200 Bristol Circle',
      city: 'Oakville',
      state: 'ON',
      zip: 'L6H 5R3',
      phone: '800-701-7460',
      mobile: '',
      tollfree: '',
      fax: '905-829-2630',
      email: 'info@daviscontrols.com',
      website: 'https://www.daviscontrols.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Thermo-Kinetics Co., Ltd.',
      region: 'Ontario',
      firstname: '',
      lastname: '',
      country: 'Canada',
      address: '6740 Invader Crescent',
      city: 'Mississauga',
      state: 'ON',
      zip: 'L5T 2B6',
      phone: '905-670-2266',
      mobile: '',
      tollfree: '',
      fax: '905-670-8530',
      email: 'inquiries@thermo-kinetics.com',
      website: 'https://www.thermo-kinetics.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Davis Controls, Ltd.',
      region: 'Quebec',
      firstname: '',
      lastname: '',
      country: 'Canada',
      address: '302-6700 Cote de Liesse',
      city: 'Saint-Laurent',
      state: 'QC',
      zip: 'H4T 2B5',
      phone: '514-737-4817',
      mobile: '',
      tollfree: '',
      fax: '514-737-9948',
      email: 'infomtl@daviscontrols.com',
      website: 'https://www.daviscontrols.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'FBN Contrôles',
      region: 'Quebec',
      firstname: '',
      lastname: '',
      country: 'Canada',
      address: '46, rue Anne-Hébert',
      city: 'Blainville',
      state: 'QC',
      zip: 'J7C 4P2',
      phone: '450-979-7365',
      mobile: '',
      tollfree: '',
      fax: '450-433-1582',
      email: 'fbncontroles@videotron.ca',
      website: 'https://www.fbncontroles.com/',
      logolink: ''
    },
    {
      type: 'NA',
      companyname: 'Thermo-Kinetics Co., Ltd.',
      region: 'Quebec',
      firstname: '',
      lastname: '',
      country: 'Canada',
      address: '5417 Vanden Abeele',
      city: 'St. Laurent',
      state: 'QC',
      zip: 'H4S 1S1',
      phone: '514-856-0370',
      mobile: '',
      tollfree: '',
      fax: '514-856-0373',
      email: 'info-montreal@thermo-kinetics.com',
      website: 'https://www.thermo-kinetics.com/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Medon GmbH',
      region: 'Austria',
      firstname: '',
      lastname: '',
      country: 'Austria',
      address: 'Greiner 724',
      intl: 'A-7534 Olbendorf',
      phone: '+43-3326-54679',
      mobile: '',
      tollfree: '',
      fax: '+43-3326-54679 4',
      email: 'office@medon.at',
      website: 'https://www.medon.at/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'CMC Technologies',
      region: 'Australia',
      firstname: '',
      lastname: '',
      country: 'Australia',
      address: 'Unit 19, 77 Bourke Rd',
      intl: 'Alexandria, NSW 2015',
      phone: '+61-2-9669-4000',
      mobile: '',
      tollfree: '',
      fax: '+61-2-9669-4111',
      email: '',
      website: 'https://www.cmctechnologies.com.au/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'BFT Mesures',
      region: 'Belgium',
      firstname: '',
      lastname: '',
      country: 'Belgium',
      address: 'Rue de la Clef 41-6c',
      intl: 'B-4650 Herve, TVA BE 0466.073.221',
      phone: '+32-87-852-851',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'info@emtec-instruments.be',
      website: 'https://www.bftmesures.be/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Serv Instrumentation',
      region: 'France',
      firstname: '',
      lastname: '',
      country: 'France',
      address: 'ZI Broteau Nord',
      intl: '69540 Irigny',
      phone: '+33-4-78514750',
      mobile: '',
      tollfree: '',
      fax: '+33-4-78515996',
      email: '',
      website: 'https://www.servinstrumentation.fr/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Tecnova HT SrL',
      region: 'Italy',
      firstname: '',
      lastname: '',
      country: 'Italy',
      address: 'Via Castellazzo, 29',
      intl: '20010 Pregnana Milanese, Milano',
      phone: '+39-2-3391-0551',
      mobile: '',
      tollfree: '',
      fax: '+39-2-3391-0563',
      email: 'web@tecnovaht.it',
      website: 'https://www.tecnovaht.it/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Hitma Instrumentatie',
      region: 'Netherlands',
      firstname: '',
      lastname: '',
      country: 'Netherlands',
      address: 'Postbus 175',
      intl: '1420 AD Uithoorn',
      phone: '+31-297-514-614',
      mobile: '',
      tollfree: '',
      fax: '+31-297-514-777',
      email: 'info@hitma-instrumentatie.nl',
      website: 'https://www.hitma-instrumentatie.nl/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Hitma Instrumentatie',
      region: 'Belgium',
      firstname: '',
      lastname: '',
      country: 'Belgium',
      address: 'Brusselstraat 51',
      intl: '2018 Antwerpen',
      phone: '+32-02-387-28-64',
      mobile: '',
      tollfree: '',
      fax: '+32-02-387-18 86',
      email: 'info@hitma-instrumentatie.nl',
      website: 'https://www.hitma-instrumentatie.nl/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'KKM Polska Sp. z o.o. Sp. K. - Emilia Sowińska',
      region: 'Poland',
      firstname: '',
      lastname: '',
      country: 'Poland',
      address: 'Ul Wojska Polskiego 107',
      intl: '97-400 Belchatow',
      phone: '+48 533 336 180',
      mobile: '',
      tollfree: '',
      fax: '+44 632 19 09',
      email: 'emilia@kkmpolska.pl',
      website: 'https://www.kkmpolska.pl/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'EPIS Grup SRL',
      region: 'Romania',
      firstname: '',
      lastname: '',
      country: 'Romania',
      address: 'Ana Ipatescu Street, No 99',
      intl: '077055 Ciorogarla, Ilfov',
      phone: '+40 21 3104311',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'ion.vasile@episgrup.ro',
      website: 'https://www.episgrup.ro/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Lana Sarrate S.A.',
      region: 'Spain',
      firstname: '',
      lastname: '',
      country: 'Spain',
      address: 'Paseo Manual Girona, 2',
      intl: '08034, Barcelona',
      phone: '+34-93-2-800101',
      mobile: '',
      tollfree: '',
      fax: '+34-93-2-806416',
      email: 'lanasarrate@lanasarrate.es',
      website: 'https://www.lanasarrate.es/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'OEM Automatic AB',
      region: 'Sweden',
      firstname: '',
      lastname: '',
      country: 'Sweden',
      address: 'Box 1011, Dalagatan 4',
      intl: 'SE-573 28 Tranås',
      phone: '+46-75-242-41-00',
      mobile: '',
      tollfree: '',
      fax: '+46-75-242-41',
      email: 'info@aut.oem.se',
      website: 'https://www.oemautomatic.se/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'DP-FLOW Ltd',
      region: 'United Kingdom',
      firstname: '',
      lastname: '',
      country: 'United Kingdom, UK',
      address: '2 A P Ellis Road',
      intl: 'Upper Rissington, Gloucestershire GL54 2QB',
      phone: '+44 1865 600245',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'sales@dp-flow.co.uk',
      website: 'https://www.dp-flow.co.uk/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Hausnet SRL',
      region: 'Argentina',
      firstname: '',
      lastname: '',
      country: 'Argentina',
      address: 'Llavallol 3350 Of: 1C (C1417AFB)',
      intl: 'Buenos Aires',
      phone: '+54-11-5219-2211',
      mobile: '+54-911-5042-5073',
      tollfree: '',
      fax: '',
      email: 'diego.varela@hausnet.com.ar',
      website: 'https://www.hausnet.com.ar/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Digitrol Indústria e Comércio Ltda',
      region: 'Brazil',
      firstname: '',
      lastname: '',
      country: 'Brazil',
      address: 'Rua Santo Arcádio, 113',
      intl: '04707-110 São Paulo – SP',
      phone: '+55 11 3511 2699',
      mobile: '',
      tollfree: '',
      fax: '+55 11 3511 2695',
      email: 'faleconosco@digitrol.com.br',
      website: 'https://www.digitrol.com.br/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Tecflow Ingenieria SpA',
      region: 'Chile',
      firstname: '',
      lastname: '',
      country: 'Chile',
      address: 'Av, Nueva Alberto Einstein 290',
      intl: 'Rancagua, O\'Higgins',
      phone: '+56-72-228-1823',
      mobile: '',
      tollfree: '',
      fax: '+56-72-228-1823',
      email: '+56-72-228-1823',
      website: 'https://www.tfi.cl/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Enerwise, S.A. de C.V.',
      region: 'Mexico',
      firstname: '',
      lastname: '',
      country: 'Mexico',
      address: 'Paseo de la Reforma # 115, piso 4',
      intl: 'Col. Lomas de Chapultepec, México, D.F. 11000',
      phone: '+52-55-8000-7270',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'info@enerwise.mx',
      website: 'https://www.enerwise.mx/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Hausnet SRL',
      region: 'Uruguay',
      firstname: '',
      lastname: '',
      country: 'Argentina',
      address: 'Llavallol 3350 Of: 1C (C1417AFB)',
      intl: 'Buenos Aires',
      phone: '+54-11-5219-2211',
      mobile: '',
      tollfree: '',
      fax: '+54-911-5042-5073',
      email: 'diego.varela@hausnet.com.ar',
      website: 'https://www.hausnet.com.ar/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'C & F Technologies cc',
      region: 'South Africa',
      firstname: '',
      lastname: '',
      country: 'South Africa',
      address: 'P.O. Box 1052 2032 Randburg',
      intl: 'South Africa',
      phone: '+27-11-792-4588',
      mobile: '',
      tollfree: '',
      fax: '+27-11-792-8494',
      email: 'sales@cftechflowmeters.co.za',
      website: 'https://www.cftechflowmeters.co.za/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'S.H.K Instruments',
      region: 'Israel',
      firstname: '',
      lastname: '',
      country: 'Israel',
      address: 'P.O. Box 8608',
      intl: 'Netanya 42505',
      phone: '+972-9-835-2722',
      mobile: '',
      tollfree: '',
      fax: '+972-9-835-2725',
      email: 'hanan@shkinst.com',
      website: 'https://shkinst.com/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Kontsis Elektronik Ölçüm Teknolojileri ve Otomasyon Sistemleri Ltd. Şti.',
      region: 'Turkey',
      firstname: '',
      lastname: '',
      country: 'Turkey',
      address: 'Yenişehir Mh. Millet Cd. Mustafa Akyol Sk.',
      intl: 'No:13/207 (MVK Work Square), Pendik / İstanbul',
      phone: '+90 216 510 24 24',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'info@kontsis.com',
      website: 'https://www.kontsis.com/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'CMC Technologies',
      region: 'Australia',
      firstname: '',
      lastname: '',
      country: 'Australia',
      address: 'Unit 19, 77 Bourke Road',
      intl: 'Alexandria, NSW 2015',
      phone: '+61-2-9669-4000',
      mobile: '',
      tollfree: '',
      fax: '+61-2-9669-4111',
      email: 'carpis@cmctechnologies.com.au',
      website: 'https://www.cmctechnologies.com.au/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Dalian Hainova Technology Co., Ltd.',
      region: 'China',
      firstname: '',
      lastname: '',
      country: 'China',
      address: 'Suite 201, Building A, No. 94',
      intl: 'Huadong Road, Ganjingzi District, Dalian, P.R.C. 116031',
      phone: '+86-411- 8654-1556',
      mobile: '+86-133- 2221-7805',
      tollfree: '',
      fax: '+86-411-8585-0357',
      email: 'sanshine88@aliyun.com',
      website: 'https://www.hainova.com.cn/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Shanghai Safe-Land Instruments Co., Ltd.',
      region: 'China',
      firstname: '',
      lastname: '',
      country: 'China',
      address: '21A ZhongFang HuaDong Mansion',
      intl: 'No. 1138 ChangAn Road, Shanghai, P.R.C. 200070',
      phone: '+86-21-6353-1910',
      mobile: '',
      tollfree: '',
      fax: '+86-21-6353-5768',
      email: 'sales@saato.fi',
      website: 'https://www.saato.fi/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'C-Net Technologies Pvt. Ltd.',
      region: 'India',
      firstname: '',
      lastname: '',
      country: 'India',
      address: '223-224, C.S.C.',
      intl: 'Chittaranjan Park, New Delhi 110019',
      phone: '+91 11 26272031',
      mobile: '+91 11 41603706',
      tollfree: '',
      fax: '',
      email: '',
      website: '',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: '',
      region: 'Malaysia',
      firstname: '',
      lastname: '',
      country: 'Malaysia',
      address: 'No. 43, Jalan Kota Raja F27/F',
      intl: 'Hicom Town Centre, Section 27, 40400 Shah Alam, Selangor, Malaysia',
      phone: '+60-3-5191-8113',
      mobile: '',
      tollfree: '',
      fax: '+60-3-5191-7113',
      email: 'pcsb@precisioncontrol.com.my',
      website: 'https://www.precisioncontrol.com.my/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Bristol Babcock Controls (NZ)',
      region: 'New Zealand',
      firstname: '',
      lastname: '',
      country: 'New Zealand',
      address: '66E Hunua Road',
      intl: 'Papakura',
      phone: '+64-9-299-7646',
      mobile: '',
      tollfree: '',
      fax: '+64-9-299-7643',
      email: 'info@bbcnz.co.nz',
      website: 'https://www.bbcnz.co.nz/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Innovative Engineers, Inc.',
      region: 'Pakistan',
      firstname: '',
      lastname: '',
      country: 'Pakistan',
      address: 'Plot #A-32, Office #2 Ground Floor',
      intl: 'Amber State Extension, Main Baloch Colony, Shahrah-e-Faisal Karachi Pakistan',
      phone: '+92-21-3452-6231-3 x802',
      mobile: '',
      tollfree: '',
      fax: '+92-21-3452-1271',
      email: 'sales@innovativeengg.com',
      website: 'https://www.innovativeengg.com/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'PNR Engineering Systems Pte, Ltd.',
      region: 'Singapore',
      firstname: '',
      lastname: '',
      country: 'Singapore',
      address: '35 Tannery Road 11-10',
      intl: 'Tannery Block, Ruby Industrial Complex, Singapore 347740',
      phone: '+65-6742-8911',
      mobile: '',
      tollfree: '',
      fax: '+65-6742-8700',
      email: 'sales@pnr-engineering.com.sg',
      website: 'https://www.pnr-engineering.com.sg/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'M & C Engineering, Inc.',
      region: 'South Korea',
      firstname: '',
      lastname: '',
      country: 'South Korea',
      address: '425-10, Annam-Ro',
      intl: 'BuPyeong-Gu InCheon 21310',
      phone: '+82-32-512-6230',
      mobile: '',
      tollfree: '',
      fax: '+82-32-513-6230',
      email: 'info@flowmeters.co.kr',
      website: 'https://www.flowmeters.co.kr/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Wei Yuang Enterprise Co., Ltd.',
      region: 'Taiwan',
      firstname: '',
      lastname: '',
      country: 'Taiwan',
      address: '2F.-6, No. 872 Jhongjheng Road',
      intl: 'Jhonghe City, Taipei County 235',
      phone: '+886-2-8221-5066',
      mobile: '',
      tollfree: '',
      fax: '+886-2-8221-3089',
      email: '',
      website: '',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Wei Yuang Enterprise Co., Ltd. Shanghai Office - Shelly Wang',
      region: 'Taiwan',
      firstname: 'Shelly',
      lastname: 'Wang',
      country: 'Taiwan',
      address: 'ROOM 1411 , SIPAI PLAZA',
      intl: '103 Cao Bao Road Shanghai, China 200233',
      phone: '',
      mobile: '',
      tollfree: '',
      fax: '',
      email: 'info@weiyuang.com.tw',
      website: 'https://www.weiyuang.com.tw/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: '湯英科技Tuner Technology Co., Ltd.',
      region: 'Taiwan',
      firstname: '',
      lastname: '',
      country: 'Taiwan',
      address: 'No.580, Dazhong 2nd Rd.',
      intl: 'Zuoying Dist., Kaohsiung City 81369',
      phone: '886-7-9743168',
      mobile: '',
      tollfree: '',
      fax: '886-7-3488819',
      email: '',
      website: 'https://www.tuner.tw/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Suvarnabhumi Automation Co., Ltd.',
      region: 'Thailand',
      firstname: '',
      lastname: '',
      country: 'Thailand',
      address: '52/82 Krungthep Kritha 15 Alley',
      intl: 'Saphan Sung, Bangkok',
      phone: '+66 2736 2020',
      mobile: '',
      tollfree: '',
      fax: '+66 2736 2149',
      email: 'sales@sautomat.co.th',
      website: 'https://sautomat.co.th/',
      logolink: ''
    },
    {
      type: 'INT',
      companyname: 'Process Technology, Inc. | Energy West Controls',
      region: 'Utah',
      firstname: "Joanne",
      lastname: "Gallagher",
      country: "United States",
      address: "1955 W Industrial Cir",
      intl: "Salt Lake City, Utah 84104",
      phone: "801.264.1114",
      mobile: "385.259.6151",
      tollfree: "",
      fax: "",
      email: "joanneg@process-tech.com",
      website: "process-tech.com",
      logolink: ""
    }
  ];

  if(distData){
    const allData_ = alldata.concat(distributorsData);
    return(allData_);
  }
  return(alldata);
}

export default RepData;
